/* globals wNumb */

import { initBootstrapSelect } from '../../Shared/js/vendor/deferred';
import { onAddressChangedEvent } from '../../Shared/React/js/components/addressSearch/AddressSearch';

async function main() {
    var page = wo$('#home-search-form');
    if (!page.length) return;

    await initBootstrapSelect();
    var homeData = WhistleOut.readLookupData(page);

    var controller = {
        linesShown: 1,
        initializedLines: [],
        address: null,

        multiLinesExpand: false,
        multiLinesSync: true,

        init: function () {
            WhistleOut.applySelectPicker(page.find('.selectpicker'));
            controller.bindLineButtons();
            controller.bindLines();
            controller.bindAddLine();
            controller.bindByoButton();
            controller.bindSearch();
            controller.bindExpanders();
            controller.bindStateSelector();
            controller.bindAddress();
            controller.initDialog();
            WhistleOut.applySelectPickersStyle();
        },

        bindAddress: function () {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            var that = this;
            addEventListener(onAddressChangedEvent, function (event) {
                that.address = event.detail.address;
            });
        },

        bindStateSelector: function () {
            try {
                var $state = page.find('#state');
                if ($state && $state.length > 0) {
                    WhistleOut.applySelectPicker($state);
                    if (typeof Cookies.get('state') !== 'undefined' && Cookies.get('state') !== null) {
                        $state.selectpicker('val', Cookies.get('state'));
                    }
                }
            } catch (e) {
                console.log('Error in bindStateSelector:', e);
            }
        },

        bindLineButtons: function () {
            page.find('a[id^="line-button-"]').click(function () {
                page.find('a[id^="line-button-"]').removeClass('circle-blue');
                wo$(this).addClass('circle-blue');
                var selectedLine = wo$(this).data('line');

                page.find('[data-number-of-lines]').text(selectedLine);

                if (!controller.multiLinesExpand) {
                    page.find('#multi-line-sync-note').slideDown(250);
                }

                controller.showLine(selectedLine);

                if (selectedLine > 1) {
                    page.find('[data-numberoflines-note]').slideDown(250);
                } else {
                    page.find('[data-numberoflines-note]').slideUp();
                }
            });

            page.find('#show-all-lines').click(function () {
                page.find('a[id^="line-button-"]').each(function () {
                    wo$(this).removeClass('hidden');
                });
                page.find('#show-all-lines').addClass('hidden');
            });

            page.find('#multi-line-expand').click(function () {
                controller.multiLinesExpand = true;
                page.find('#multi-line-sync-note').slideUp();
                page.find('#multi-lines-section').slideDown(250);
            });
        },

        bindLines: function (rebind) {
            var maxLines = homeData.maxLines;
            for (var line = 1; line <= maxLines; line++) {
                var lineContainer = page.find('.search-line-' + line);
                var lineConfig = homeData.lines[line - 1];
                if (lineConfig) {
                    controller.bindLine(lineConfig, lineContainer, rebind);
                }
            }
        },

        bindLine: function (lineConfig, lineContainer, rebind) {
            WhistleOut.createSlider(
                lineContainer.find('div[name="data-slider"]'),
                lineContainer.find('span[name="data-text"]'),
                lineConfig.dataSlider,
                wNumb({ decimals: 0 }),
                function () {
                    controller.syncDataSliders(lineConfig);
                },
                rebind
            );

            if (homeData.showPriceFilter) {
                if (lineConfig.lineNumber === 1) {
                    WhistleOut.createDoubleSlider(
                        lineContainer.find('div[name="price-slider"]'),
                        lineContainer.find('span[name="price-text"]'),
                        homeData.priceSlider,
                        wNumb({ decimals: 0 }),
                        null,
                        null,
                        true
                    );
                }
            } else {
                if (lineConfig.minutesSlider) {
                    WhistleOut.createSlider(
                        lineContainer.find('div[name="minutes-slider"]'),
                        lineContainer.find('span[name="minutes-text"]'),
                        lineConfig.minutesSlider,
                        wNumb({ decimals: 0 }),
                        function () {
                            controller.syncMinutesSliders(lineConfig);
                        },
                        rebind
                    );
                }
                if (lineConfig.callsSlider) {
                    WhistleOut.createSlider(
                        lineContainer.find('div[name="calls-slider"]'),
                        lineContainer.find('span[name="calls-text"]'),
                        lineConfig.callsSlider,
                        wNumb({ decimals: 0 }),
                        function () {
                            controller.syncCallsSliders(lineConfig);
                        },
                        rebind
                    );
                }
                WhistleOut.createSlider(
                    lineContainer.find('div[name="messages-slider"]'),
                    lineContainer.find('span[name="messages-text"]'),
                    lineConfig.messagesSlider,
                    wNumb({ decimals: 0 }),
                    function () {
                        controller.syncMessagesSliders(lineConfig);
                    },
                    rebind
                );
            }
        },

        syncDataSliders: function (sourceLineConfig) {
            if (controller.multiLinesSync && sourceLineConfig.lineNumber > 1) {
                controller.multiLinesSync = false;
            }

            var sync = controller.multiLinesSync && sourceLineConfig.lineNumber === 1;
            if (sync) {
                var maxLines = homeData.maxLines;
                for (var line = 2; line <= maxLines; line++) {
                    homeData.lines[line - 1].dataSlider.currentPeg.index = sourceLineConfig.dataSlider.currentPeg.index;
                    controller.bindLine(homeData.lines[line - 1], page.find('.search-line-' + line), true);
                }
            }
        },

        syncMinutesSliders: function (sourceLineConfig) {
            if (controller.multiLinesSync && sourceLineConfig.lineNumber > 1) {
                controller.multiLinesSync = false;
            }

            var sync = controller.multiLinesSync && sourceLineConfig.lineNumber === 1;
            if (sync) {
                var maxLines = homeData.maxLines;
                for (var line = 2; line <= maxLines; line++) {
                    homeData.lines[line - 1].minutesSlider.currentPeg.index =
                        sourceLineConfig.minutesSlider.currentPeg.index;
                    controller.bindLine(homeData.lines[line - 1], page.find('.search-line-' + line), true);
                }
            }
        },

        syncCallsSliders: function (sourceLineConfig) {
            if (controller.multiLinesSync && sourceLineConfig.lineNumber > 1) {
                controller.multiLinesSync = false;
            }

            var sync = controller.multiLinesSync && sourceLineConfig.lineNumber === 1;
            if (sync) {
                var maxLines = homeData.maxLines;
                for (var line = 2; line <= maxLines; line++) {
                    homeData.lines[line - 1].callsSlider.currentPeg.index =
                        sourceLineConfig.callsSlider.currentPeg.index;
                    controller.bindLine(homeData.lines[line - 1], page.find('.search-line-' + line), true);
                }
            }
        },

        syncMessagesSliders: function (sourceLineConfig) {
            if (controller.multiLinesSync && sourceLineConfig.lineNumber > 1) {
                controller.multiLinesSync = false;
            }

            var sync = controller.multiLinesSync && sourceLineConfig.lineNumber === 1;
            if (sync) {
                var maxLines = homeData.maxLines;
                for (var line = 2; line <= maxLines; line++) {
                    homeData.lines[line - 1].messagesSlider.currentPeg.index =
                        sourceLineConfig.messagesSlider.currentPeg.index;
                    controller.bindLine(homeData.lines[line - 1], page.find('.search-line-' + line), true);
                }
            }
        },

        bindAddLine: function () {
            page.find('#add-another-line').click(function () {
                controller.showLine(controller.linesShown + 1);
            });
        },

        bindByoButton: function () {
            page.find('[data-show-byo-button]').each(function () {
                var source = wo$(this);

                WhistleOut.MobilePhones.bindByoPopover(source, homeData.byoPopoverContent, function () {
                    var line = source.data('show-byo-button');
                    if (typeof line === 'undefined' || line < 2) {
                        line = 1;
                    }
                    controller.removePhone(true, null, line);
                });
            });
        },

        bindExpanders: function () {
            page.find('*[data-expander-button]').click(function () {
                var line = wo$(this).data('line');
                var lineContainer = page.find('.search-line-' + line);

                if (lineContainer.find('span[data-expander-icon]').hasClass('fa-chevron-down')) {
                    lineContainer.find('div[data-show-when-expanded]').hide();
                    lineContainer.find('div[data-show-when-expanded]').removeClass('hidden');
                    lineContainer.find('*[data-show-when-collapsed]').addClass('hidden');
                    lineContainer.find('div[data-show-when-expanded]').slideDown(250);
                } else {
                    lineContainer.find('div[data-show-when-expanded]').slideUp(function () {
                        lineContainer.find('div[data-show-when-expanded]').addClass('hidden');
                        lineContainer.find('*[data-show-when-collapsed]').removeClass('hidden');
                    });
                }

                lineContainer.find('span[data-expander-icon]').toggleClass('fa-chevron-down');
                lineContainer.find('span[data-expander-icon]').toggleClass('fa-chevron-up');
            });
        },

        bindSearch: function () {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            var that = this;
            page.find('#search').on('click', function () {
                if (
                    homeData.forceCurrentSupplier &&
                    page.find('select#currently-with').find(':selected').val() === ''
                ) {
                    page.find('#current-supplier-error').show();
                    return;
                }
                page.find('#current-supplier-error').hide();

                var config = WhistleOut.getSiteConfiguration();
                if (
                    config.autoDetectLocation &&
                    (!that.address || !that.address.label || that.address.label.length === 0)
                ) {
                    page.find('#search .fa-spinner').show();
                    WhistleOut.getCurrentLocation(
                        function (e) {
                            var address = e.label;
                            if (address.length > 1) {
                                var siteCountry = (config.countryCode || '').toLowerCase();
                                var clientCountry = (e.countryCode || '').toLowerCase();
                                if (siteCountry === clientCountry) {
                                    that.address = { label: address };
                                }
                            }
                            that.doSearch();
                        },
                        function () {
                            that.doSearch();
                        }
                    );
                } else {
                    that.doSearch();
                }
            });
        },

        doSearch: function () {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            var that = this;
            page.find('#search .fa-spinner').show();
            var queryData = {};
            queryData.lines = [];
            var currentLine = 1;
            wo$.each(homeData.lines, function (index, line) {
                if (currentLine > controller.linesShown) return;

                var lineData = {};

                var data = line.dataSlider.currentPeg.value;
                if (WhistleOut.isNumber(data)) lineData.data = data;

                if (line.callsSlider) {
                    var calls = line.callsSlider.currentPeg.value;
                    if (WhistleOut.isNumber(calls)) lineData.calls = calls;
                }

                if (line.minutesSlider) {
                    var minutes = line.minutesSlider.currentPeg.value;
                    if (WhistleOut.isNumber(minutes)) lineData.minutes = minutes;
                }

                var sms = line.messagesSlider.currentPeg.value;
                if (WhistleOut.isNumber(sms)) lineData.sms = sms;

                lineData.phone = line.phoneShortUrl;
                lineData.allowPhoneSub = line.allowPhoneSub === true ? 1 : 0;
                lineData.byoPhoneModelId = line.byoPhoneModel ? line.byoPhoneModel.selectedModelId : null;

                if (line.phonePriceTypes && line.phonePriceTypes.length > 0) {
                    lineData.phonePriceTypes = line.phonePriceTypes.map(function (priceTypeValue) {
                        return { key: priceTypeValue, value: priceTypeValue };
                    });
                }

                lineData.simOnly = line.simOnly;

                queryData.lines.push(lineData);
                currentLine++;
            });

            if (that.address) {
                queryData.address = that.address.label;
            }

            var selectedCarrier = page.find('select#currently-with').find(':selected');
            queryData.currentsupplier = selectedCarrier.data('short-url');

            var selectedValues = page.find('#discount-eligibility').selectpicker('val');
            queryData.eligibility = this.getSelectedEligibility(selectedValues);

            if (homeData.showPriceFilter) {
                queryData.minSpend = homeData.priceSlider.currentPeg1.value;
                queryData.maxSpend = homeData.priceSlider.currentPeg2.value;
            }

            var suppliers = [];
            var defaultSuppliers = homeData.defaultSuppliers;
            var selectedNetworks = page.find('#network').val();
            if (selectedNetworks && selectedNetworks.length > 0) {
                queryData.network = selectedNetworks.join(',');

                wo$.each(selectedNetworks, function (index, network) {
                    var suppliersString = page.find('select#network option[value="' + network + '"]').data('suppliers');
                    if (suppliersString && suppliersString.length > 0) {
                        wo$.each(suppliersString.split(','), function (i, supplierUrl) {
                            if (
                                suppliers.filter(function (s) {
                                    return s === supplierUrl;
                                }).length === 0 &&
                                (defaultSuppliers.length === 0 || wo$.inArray(supplierUrl, defaultSuppliers) > -1)
                            ) {
                                suppliers.push(supplierUrl);
                            }
                        });
                    }
                });
            }

            if (suppliers.length === 0 && defaultSuppliers.length > 0) {
                suppliers = defaultSuppliers;
            }

            if (suppliers.length > 0) {
                queryData.allSuppliers = [];
                queryData.suppliers = suppliers;
            }

            var $state = page.find('#state');
            if ($state && $state.length > 0) {
                queryData.state = $state.val();
            }

            if (
                homeData.defaultTab != null &&
                homeData.useDefaultTab &&
                queryData.lines.length === 1 &&
                (!queryData.lines[0].phonePriceTypes || queryData.lines[0].phonePriceTypes.length !== 1)
            ) {
                queryData.tab = homeData.defaultTab;
            }

            queryData.customer = homeData.defaultCustomerType;

            if (homeData.defaultMaxPhoneUpfront != null) {
                queryData.maxupfront = homeData.defaultMaxPhoneUpfront;
            }

            if (homeData.refurbishedPhones) {
                queryData.refurbishedPhones = homeData.refurbishedPhones;
            }

            var queryString = WhistleOut.MobilePhones.generateQueryString(queryData);
            if (controller.linesShown >= 1) {
                if (queryString.length > 0) {
                    location.href = homeData.searchUrl + '?' + queryString;
                } else {
                    location.href = homeData.searchUrl;
                }
            } else if (queryString.length > 0) {
                location.search = queryString;
            }
        },

        getSelectedEligibility: function (values) {
            var siteConfig = WhistleOut.getSiteConfiguration();
            return siteConfig.mobilePhonesConfig.showEligibilityFilter && values != null
                ? `none,${values}`
                : null;
        },

        showLine: function (line) {
            var maxLines = homeData.maxLines;
            if (line > maxLines) return;

            for (var l = 1; l <= maxLines; l++) {
                var lineContainer = page.find('.search-line-' + l);
                if (l <= line) {
                    if (l > controller.linesShown && l > 1) lineContainer.hide();
                    lineContainer.removeClass('hidden');
                    if (l > controller.linesShown && l > 1) lineContainer.slideDown(250);
                } else {
                    if (l <= controller.linesShown)
                        lineContainer.slideUp(function () {
                            lineContainer.addClass('hidden');
                        });
                    else lineContainer.addClass('hidden');
                }
            }
            controller.linesShown = line;

            if (line === maxLines) {
                page.find('#add-another-line').addClass('hidden');
            } else {
                page.find('#add-another-line').removeClass('hidden');
            }
        },

        phoneSelected: function (phoneData) {
            var sync = controller.multiLinesSync && phoneData.currentLine === 1;
            var linesToUpdate = sync ? new Array(homeData.maxLines) : new Array(1);

            if (!sync) {
                linesToUpdate[0] = phoneData.currentLine - 1;
            } else {
                for (var i = 0; i < homeData.maxLines; i++) {
                    linesToUpdate[i] = i;
                }
            }

            if (controller.multiLinesSync && phoneData.currentLine !== 1) {
                controller.multiLinesSync = false;
            }

            for (var index = 0; index < linesToUpdate.length; index++) {
                var currentLine = linesToUpdate[index];
                homeData.lines[currentLine].phoneShortUrl = phoneData.phoneShortUrl;
                homeData.lines[currentLine].allowPhoneSub = phoneData.allowSub;
                if (phoneData.priceTypes && phoneData.priceTypes.length > 0) {
                    homeData.lines[currentLine].phonePriceTypes = wo$.map(phoneData.priceTypes, function (value) {
                        return [value];
                    });
                } else {
                    homeData.lines[currentLine].phonePriceTypes = [];
                }

                var lineContainer = page.find('.search-line-' + (currentLine + 1));
                lineContainer.find('img[data-phone-image]').attr('src', phoneData.phoneImage);
                lineContainer.find('img[data-phone-image]').attr('data-src', phoneData.phoneImage);
                lineContainer.find('img[data-phone-image]').attr('alt', phoneData.phoneName);
                lineContainer.find('a[data-phone-image-link]').attr('href', phoneData.phoneExtraLargeImage);
                lineContainer.find('*[data-phone-name]').text(phoneData.phoneName);
                lineContainer.find('*[data-optional-text]').addClass('hidden');

                homeData.lines[currentLine].byoPhoneModel = null;
                lineContainer.find('*[data-byod-model]').text('');

                if (phoneData.phoneShortUrl) {
                    homeData.lines[currentLine].simOnly = false;
                    lineContainer.find('*[data-show-when-byod]').addClass('hidden');
                    lineContainer.find('*[data-show-when-no-phone]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone-or-byo]').addClass('hidden');
                    lineContainer.find('*[data-show-when-phone-selected]').removeClass('hidden');
                    lineContainer.find('*[data-show-when-phone-or-byod-selected]').removeClass('hidden');
                    lineContainer.find('*[data-phone-name]').removeClass('hidden');
                    lineContainer.find('*[data-change-phone]').removeClass('hidden');
                    homeData.useDefaultTab = !phoneData.hasPrepaidPlansOnly;
                    homeData.refurbishedPhones = phoneData.refurbishedPhones;
                } else {
                    homeData.lines[currentLine].simOnly = true;
                    homeData.useDefaultTab = true;
                    lineContainer.find('*[data-show-when-phone-selected]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone-or-byo]').addClass('hidden');
                    lineContainer.find('*[data-show-when-no-phone]').removeClass('hidden');
                    lineContainer.find('*[data-phone-name]').removeClass('hidden');
                    lineContainer.find('*[data-change-phone]').removeClass('hidden');
                }

                if (phoneData.is5GCapable) {
                    lineContainer.find('[data-has-5g]').show();
                } else {
                    lineContainer.find('[data-has-5g]').hide();
                }

                if (index === 0) {
                    lineContainer.find('img[data-phone-large-image]').attr('src', phoneData.phoneLargeImage);
                    lineContainer.find('img[data-phone-large-image]').attr('alt', phoneData.phoneName);

                    lineContainer.find('a[data-phone-specs-button]').each(function () {
                        wo$(this).data('phone-specs-button', phoneData.phoneShortUrl);
                    });

                    WhistleOut.bindPhoneSpecsModal(lineContainer);

                    lineContainer.find('a[data-phone-gallery-button]').each(function () {
                        wo$(this).data('phone-gallery-button', phoneData.phoneShortUrl);
                    });

                    WhistleOut.bindPhoneGallery(lineContainer);
                }
            }
        },

        removePhone: function (isByo, byoPhoneModel, line) {
            if (typeof line === 'undefined' || !(line > 0)) {
                line = 1;
            }

            var sync = controller.multiLinesSync && line === 1;
            var linesToUpdate = sync ? new Array(homeData.maxLines) : new Array(1);

            if (!sync) {
                linesToUpdate[0] = line - 1;
            } else {
                for (var i = 0; i < homeData.maxLines; i++) {
                    linesToUpdate[i] = i;
                }
            }

            if (controller.multiLinesSync && line !== 1) {
                controller.multiLinesSync = false;
            }

            for (var index = 0; index < linesToUpdate.length; index++) {
                var currentLine = linesToUpdate[index] + 1;

                var modalData = {
                    currentLine: currentLine,
                    phoneShortUrl: null,
                    phoneImage: null,
                    phoneName: null,
                    allowSub: false,
                    priceTypes: []
                };

                homeData.lines[modalData.currentLine - 1].phoneShortUrl = modalData.phoneShortUrl;
                homeData.lines[modalData.currentLine - 1].allowPhoneSub = modalData.allowSub;
                homeData.lines[modalData.currentLine - 1].phonePriceTypes = modalData.priceTypes;
                homeData.lines[modalData.currentLine - 1].simOnly = isByo === true;
                homeData.lines[modalData.currentLine - 1].byoPhoneModel = byoPhoneModel;

                var lineContainer = page.find('.search-line-' + modalData.currentLine);

                lineContainer.find('[data-has-5g]').hide();

                if (isByo === true) {
                    var byodModelText = '';

                    if (byoPhoneModel && byoPhoneModel.imageUrl) {
                        lineContainer.find('*[data-show-when-byod]').removeClass('hidden');
                        lineContainer.find('*[data-show-when-phone-or-byod-selected]').removeClass('hidden');
                        lineContainer.find('img[data-phone-image]').attr('src', byoPhoneModel.imageUrl.medium);
                        lineContainer.find('img[data-phone-image]').attr('data-src', byoPhoneModel.imageUrl.medium);
                        byodModelText = 'Model: ' + byoPhoneModel.selectedModel;

                        if (byoPhoneModel.is5GCapable) {
                            lineContainer.find('[data-has-5g]').show();
                        } else {
                            lineContainer.find('[data-has-5g]').hide();
                        }

                        if (index === 0) {
                            lineContainer.find('img[data-phone-large-image]').attr('src', byoPhoneModel.imageUrl.large);

                            lineContainer.find('a[data-phone-specs-button]').each(function () {
                                wo$(this).data('phone-specs-button', byoPhoneModel.shortUrl);
                            });

                            WhistleOut.bindPhoneSpecsModal(lineContainer);

                            lineContainer.find('a[data-phone-gallery-button]').each(function () {
                                wo$(this).data('phone-gallery-button', byoPhoneModel.shortUrl);
                            });

                            WhistleOut.bindPhoneGallery(lineContainer);
                        }
                    } else {
                        lineContainer.find('*[data-show-when-byod]').addClass('hidden');
                        lineContainer.find('*[data-show-when-phone-or-byod-selected]').addClass('hidden');
                        lineContainer
                            .find('img[data-phone-image]')
                            .attr('src', lineContainer.find('img[data-phone-image]').data('byo-phone-image'));
                        lineContainer
                            .find('img[data-phone-image]')
                            .attr('data-src', lineContainer.find('img[data-phone-image]').data('byo-phone-image'));
                        lineContainer
                            .find('img[data-phone-large-image]')
                            .attr('src', lineContainer.find('img[data-phone-large-image]').data('byo-phone-image'));
                        lineContainer.find('img[data-phone-large-image]').attr('alt', homeData.byodSimOnly);
                    }

                    lineContainer
                        .find('*[data-phone-name]')
                        .text(byoPhoneModel ? byoPhoneModel.name : homeData.byoLabel);
                    lineContainer.find('*[data-byod-model]').text(byodModelText);
                    lineContainer.find('*[data-optional-text]').addClass('hidden');
                    lineContainer.find('*[data-price-types-values]').text('');

                    lineContainer.find('*[data-show-when-phone-selected]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone-or-byo]').removeClass('hidden');
                    lineContainer.find('*[data-show-when-no-phone]').removeClass('hidden');
                    lineContainer.find('*[data-phone-name]').removeClass('hidden');
                    lineContainer.find('*[data-change-phone]').removeClass('hidden');
                } else {
                    lineContainer
                        .find('img[data-phone-image]')
                        .attr('src', lineContainer.find('img[data-phone-image]').data('add-phone-image'));
                    lineContainer
                        .find('img[data-phone-image]')
                        .attr('data-src', lineContainer.find('img[data-phone-image]').data('add-phone-image'));
                    lineContainer.find('img[data-phone-image]').attr('alt', homeData.selectPhoneModel);
                    lineContainer
                        .find('img[data-phone-large-image]')
                        .attr('src', lineContainer.find('img[data-phone-large-image]').data('add-phone-image'));
                    lineContainer.find('img[data-phone-large-image]').attr('alt', homeData.selectPhoneModel);
                    lineContainer.find('*[data-phone-name]').text(modalData.phoneName);
                    lineContainer.find('*[data-optional-text]').addClass('hidden');
                    lineContainer.find('*[data-price-types-values]').text('');
                    lineContainer.find('*[data-byod-model]').text('');

                    lineContainer.find('*[data-show-when-phone-selected]').addClass('hidden');
                    lineContainer.find('*[data-show-when-phone-or-byod-selected]').addClass('hidden');
                    lineContainer.find('*[data-show-when-byod]').addClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone]').removeClass('hidden');
                    lineContainer.find('*[data-show-when-empty-phone-or-byo]').removeClass('hidden');
                    lineContainer.find('*[data-phone-name]').addClass('hidden');
                    lineContainer.find('*[data-change-phone]').addClass('hidden');
                }
            }
        },

        showByodModal: function (source) {
            var line = source.data('show-byo-phone-modal-selector');

            var byoModalContainer = wo$('#byo-modal-container');
            if (byoModalContainer && byoModalContainer.length > 0) {
                WhistleOut.byoPhonePickerController.show(function (selectedModel) {
                    if (typeof line === 'undefined' || line < 2) {
                        line = 1;
                    }
                    controller.removePhone(true, selectedModel, line);
                });
            } else {
                page.find('[data-byod-loading-block]').show();
                var siteConfiguration = WhistleOut.getSiteConfiguration();
                wo$.ajax({
                    url: siteConfiguration.byoPhonePickerModalUrl,
                    type: 'GET',
                    success: function (result) {
                        page.find('[data-byod-loading-block]').hide();
                        if (result !== '') {
                            if (!byoModalContainer || byoModalContainer.length < 1) {
                                wo$('body').append('<div id="byo-modal-container"></div>');
                                byoModalContainer = wo$('#byo-modal-container');
                            }
                            byoModalContainer.html(result);
                            WhistleOut.byoPhonePickerController.show(function (selectedModel) {
                                if (typeof line === 'undefined' || line < 2) {
                                    line = 1;
                                }
                                controller.removePhone(true, selectedModel, line);
                            });
                        }
                    }
                });
            }
        },

        bindByodPopover: function (sourceElement, content) {
            wo$('body')
                .off('click', WhistleOut.MobilePhones.hideByodPopover)
                .click(WhistleOut.MobilePhones.hideByodPopover);
            wo$(sourceElement)
                .off('click')
                .on('click', function () {
                    wo$(sourceElement)
                        .off('shown.bs.popover')
                        .on('shown.bs.popover', function () {
                            wo$('[data-show-byo-phone-modal]').click(function () {
                                wo$(sourceElement).popover('destroy');
                                controller.showByodModal(sourceElement);
                            });
                            wo$('[data-show-all-byod]').click(function () {
                                wo$(sourceElement).popover('destroy');
                                controller.removePhone(true, null, sourceElement.data('show-byo-phone-modal-selector'));
                            });
                        });

                    if (wo$(sourceElement).data('bs.popover')) {
                        wo$(sourceElement).popover('toggle');
                    } else {
                        wo$(sourceElement).popover({
                            container: 'body',
                            placement: 'top',
                            trigger: 'manual',
                            html: true,
                            content: content
                        });
                        wo$(sourceElement).popover('show');
                    }
                });
        },

        initDialog: function () {
            page.find('*[data-show-byo-phone-modal-selector]').each(function () {
                var line = wo$(this).data('show-byo-phone-modal-selector');
                var html = '<div>';
                html +=
                    '<a data-show-byo-phone-modal="' +
                    line +
                    '" tabindex="0" role="button" class="btn btn-primary btn-block font-5 mar-t-2 mar-b-3 text-break">' +
                    homeData.byodIKnowThePhoneIWantToBring +
                    '</a>	';
                html +=
                    '<a data-show-all-byod tabindex="0" role="button" class="btn btn-primary btn-block font-5 mar-t-2 mar-b-2">' +
                    homeData.byodSeeAllResults +
                    '</a>';
                html += '</div>';

                controller.bindByodPopover(wo$(this), html);
            });

            page.find('*[data-show-phone-modal]').each(function () {
                var currentLine = wo$(this).data('show-phone-modal');
                var html = '<div>';
                html +=
                    '<a data-phone-picker-button tabindex="0" role="button" class="btn btn-primary btn-block font-5 mar-t-2 mar-b-3">' +
                    homeData.iKnowThePhoneIWant +
                    '</a>	';
                html +=
                    '<a data-phone-picker-wizard-button tabindex="0" role="button" class="btn btn-primary btn-block font-5 mar-t-2 mar-b-2">' +
                    homeData.helpMeChoose +
                    '</a>';
                html += '</div>';
                WhistleOut.MobilePhones.bindPhonePickerPopover(wo$(this), html, function (selectedPhone) {
                    if (selectedPhone.id === -1) {
                        var line = currentLine;
                        if (typeof line === 'undefined' || line < 2) {
                            line = 1;
                        }
                        controller.removePhone(true, null /*ToDo: Handle*/, line);
                    } else {
                        var phoneData = {};
                        phoneData.currentLine = currentLine;
                        phoneData.phoneShortUrl = selectedPhone.shortUrl;
                        phoneData.allowSub = selectedPhone.similarPhones;
                        phoneData.priceTypes = selectedPhone.priceTypes;
                        phoneData.phoneImage = selectedPhone.imageUrl.medium;
                        phoneData.phoneLargeImage = selectedPhone.imageUrl.large;
                        phoneData.phoneExtraLargeImage = selectedPhone.imageUrl.extraLarge;
                        phoneData.phoneOtherImageUrls = selectedPhone.otherImageUrls;
                        phoneData.phoneName = selectedPhone.name;
                        phoneData.hasPrepaidPlansOnly = selectedPhone.hasPrepaidPlansOnly;
                        phoneData.refurbishedPhones = selectedPhone.refurbishedPhones;
                        phoneData.is5GCapable = selectedPhone.is5GCapable;
                        controller.phoneSelected(phoneData);
                    }
                });
            });

            var modalData = {
                currentLine: wo$(this).data('show-phone-modal'),
                phoneShortUrl: null,
                phoneImage: null,
                phoneName: null,
                allowSub: false,
                priceTypes: []
            };

            page.find('*[data-show-phone-modal]').click(function () {
                var currentLine = wo$(this).data('show-phone-modal');

                modalData = {
                    currentLine: currentLine,
                    phoneShortUrl: homeData.lines[currentLine - 1].phoneShortUrl,
                    phoneImage: homeData.lines[currentLine - 1].phoneImage,
                    phoneName: homeData.lines[currentLine - 1].phoneName,
                    allowSub: homeData.lines[currentLine - 1].allowPhoneSub,
                    priceTypes: homeData.lines[currentLine - 1].phonePriceTypes,
                    lines: controller.linesShown
                };

                if (wo$.inArray(currentLine, controller.initializedLines) < 0) {
                    modalData.allowSub = false;
                    modalData.priceTypes = [];
                    controller.initializedLines.push(currentLine);
                }
            });

            page.find('*[data-remove-phone]').click(function () {
                var line = wo$(this).data('remove-phone');
                controller.removePhone(line > 1, null, line);
            });
        }
    };

    controller.init();
}

WhistleOut.initModule(import.meta.url, main);
